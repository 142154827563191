import React from 'react';
import logoSmall from './img/logo_small.png';

export const NavBarEmpty = () => {
	return (
		<nav className="navbar navbar-dark bg-dark">
			<a className="navbar-brand" href="#">
				<img
					src={logoSmall}
					width="30"
					height="30"
					className="d-inline-block align-top"
					style={{ marginRight: '10px' }}
					alt=""
				/>
				8th Core
			</a>
		</nav>
	);
};
